import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

class BlogPostTemplate extends React.Component {
  render() {
    const { data, location } = this.props
    const post = data.markdownRemark
    const siteUrl = data.site.siteMetadata.siteUrl
    const { previous, next } = this.props.pageContext

    let disqusConfig = {
      url: `${siteUrl+location.pathname}`,
      title: post.frontmatter.title,
    }

    return (
      <DefaultLayout>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <article className="article-page">
          <div className="page-content">
            {post.frontmatter.img && (
              <div className="page-cover-image">
                <figure>
                  <Img
                    className="page-image"
                    key={post.frontmatter.img.childImageSharp.fluid.src}
                    fluid={post.frontmatter.img.childImageSharp.fluid}
                  />
                </figure>
              </div>
            )}
            <div className="wrap-content">
              <header className="header-page">
                <h1 className="page-title">{post.frontmatter.title}</h1>
                <div className="page-date">
                  <span>{post.frontmatter.date}&nbsp;&nbsp;&mdash;&nbsp;&nbsp;<CommentCount config={disqusConfig}/></span>
                </div>
              </header>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className="page-footer">
                <div className="page-tag">
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.map(tag => (
                      <span className="tag" key={tag}>
                        # {tag}
                      </span>
                    ))}
                </div>
              </div>
              <section>
                <Disqus config={disqusConfig} />
              </section>
            </div>
          </div>
        </article>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        tags
        img {
          childImageSharp {
            fluid(maxWidth: 3720) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
